<template>
  <div>
    <v-btn class="ma-3" color="flatButton" text  to="/new-channel">
      <v-icon left dark>mdi-plus</v-icon>New Channel
    </v-btn>
    <v-data-table
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="channels"
      :search="search"
    >
      <template v-slot:item.name="{ item }">
        <v-btn color="flatButton" text :to="`/channels/${item.id}`">{{ item.name }}</v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      channels: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Total Outlets", value: "bpartner.length" },
        { text: "Description", value: "descript" },
      ],
    };
  },
  methods: {
    getChannels() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/channels`)
        .then((res) => {
          self.channels = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getChannels();
  },
};
</script>
